import React from 'react';

import { useGetLegionInstructionTextByLanguage } from '@widgets/legions/hooks/useGetLegionInstructionTextByLanguage';
import QuestionLayout from '@widgets/questions-answers/QuestionLayout';

interface QuestionPageProps {
  location: any;
}

const LegionInstructionQuestionPage: React.FC<QuestionPageProps> = ({ location }) => {
  const t = useGetLegionInstructionTextByLanguage();

  const question = 'legion-instruction-question-2';
  const answer = 'legion-instruction-answer-2';

  return (
    <QuestionLayout location={location} question={question}>
      {t(answer)}
    </QuestionLayout>
  );
};

export default LegionInstructionQuestionPage;
